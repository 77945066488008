import { useQuery, useQueryClient } from "react-query";
import { PromotionalLeadClient } from "services/api.clients";
import { useRecoilValue } from "recoil";
import dayjs from "dayjs";
import { Permissions } from "global/permissions";
import useUserHasPermissions from "hooks/useUserHasPermissions";
import { PlatformActiveBrand } from "../selectors/PlatformBrands.selector";
import { PlatformActiveMarket } from "../selectors/PlatformMarkets.selector";

export function useBadgeCount(isLoading?: boolean) {
    const queryClient = useQueryClient();

    const startDate = dayjs().subtract(30, "day").startOf("day").toDate().toISOString();
    const endDate = dayjs().add(30, "day").endOf("day").toDate().toISOString();

    const activeMarket = useRecoilValue(PlatformActiveMarket) || {};
    const activeBrand = useRecoilValue(PlatformActiveBrand) || {};

    const hasPermission = useUserHasPermissions();

    const queryKey = [
        "PromotionalLeadClient.apiPromotionalLeadsBadgeCountGet",
        startDate,
        endDate,
        activeMarket?.id,
        activeBrand?.id,
    ];

    const query = useQuery({
        queryKey,
        queryFn: ({ queryKey: [_, ...params] }) =>
            PromotionalLeadClient.apiPromotionalLeadsBadgeCountGet(...params)
                .then((res) => res.data)
                .then((res) => res.data),
        enabled: !isLoading && hasPermission(Permissions.AfterSalesManagement),
    });

    const invalidate = () =>
        queryClient.invalidateQueries({
            queryKey,
        });

    return {
        ...query,
        invalidate,
    };
}
